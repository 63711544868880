

















import {Component, Vue} from "vue-property-decorator";
import {localize} from 'vee-validate';

@Component
export default class I18n extends Vue {
    get i18n_locale_img() {
        return require(`@/assets/images/flags/${this.$i18n.locale}.png`)
    }

    get getCurrentLocaleData() {
        const locale = this.$i18n.locale;
        if (locale == "en") return {flag: "us", lang: 'English'}
        else if (locale == "pt") return {flag: "br", lang: 'Portuguese'}
        else if (locale == "fr") return {flag: "fr", lang: 'French'}
        else if (locale == "de") return {flag: "de", lang: 'German'}
        else return {flag: "vi", lang: 'Tiếng Việt'};
    }

    updateLocale(locale: string) {
        this.$i18n.locale = locale;
    }
}

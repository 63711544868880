





































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import * as signalR from "@microsoft/signalr";
import HNavMenu from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue';
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue';
import TheNavbarVertical from '@/layouts/components/navbar/TheNavbarVertical.vue';
import TheFooter from '@/layouts/components/TheFooter.vue';
import { themeConfig } from '@/../themeConfig';
import VNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue';
import TheCustomizer from '@/layouts/components/customizer/TheCustomizer.vue';
import BackToTop from 'vue-backtotop';
import { Getter } from 'vuex-class';
import { MenuService, AppView } from "@/services/MenuService";
import _ from 'lodash';
import { namespace } from 'vuex-class';
import router from '@/router';

const appStoreModule = namespace('app');

@Component({
    components: {
        TheCustomizer,
        BackToTop,
        HNavMenu,
        TheFooter,
        TheNavbarHorizontal,
        TheNavbarVertical,
        VNavMenu
    }
})
export default class Main extends Vue {
    @Getter('oidcUser') user!: any;
    @Getter("oidcAccessToken") accessToken!: string;
    @appStoreModule.Getter('requestFail') requestFail!: any;
    @appStoreModule.Action('setRequestFail') setRequestFail!: any;

    disableCustomizer = themeConfig.disableCustomizer;
    footerType = themeConfig.footerType || 'static';
    hideScrollToTop = themeConfig.hideScrollToTop;
    isNavbarDark = false;
    navbarColor = themeConfig.navbarColor || '#fff';
    navbarType = themeConfig.navbarType || 'floating';
    //navMenuItems = navMenuItems;
    navMenuItems: Array<AppView> = [];
    menuService: MenuService;
    routerTransition = themeConfig.routerTransition || 'none';
    routeTitle: string = '';
    _connection: any;

    @Watch('requestFail')
    onRequestFail(data: { code: number, message: string }) {
        if (data) {
            this.$vs.notify({
                title: 'Thông báo',
                text: data.code === 403 ? 'Bạn không có quyền thực hiện chức năng này' : data.message,
                color: 'danger'
            });
        }
    }

    constructor() {
        super();
        this.menuService = new MenuService();
    }

    created() {
        const color = this.navbarColor == '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor;
        this.updateNavbarColor(color);
        this.setNavMenuVisibility(this.$store.state.mainLayoutType);
        this.getPages();
    }

    // mounted() {
    //     this.routeTitle = this.$route.meta.pageTitle;
    // }

    async getPages() {
        const result = await this.menuService.getPages();

        this.navMenuItems = result.apps;

        //this.$store.state.starredPages =


        this.$store.state.bookMarkedPages = _.filter(result.pages, function (item) {

            return item.isBookmarked;
        });

        this.$store.state.allPages = result.pages;
    }

    @Watch('isThemeDark')
    onChangeTheme(val: any) {
        const color = this.navbarColor == '#fff' && val ? '#10163a' : '#fff';
        this.updateNavbarColor(color);
    }

    @Watch('$store.state.mainLayoutType')
    onChangeLayoutType(val: any) {
        this.setNavMenuVisibility(val);
    }

    @Watch("$route", {immediate: true, deep: true})
    onRouteChange(value: any) {
        // @ts-ignore
        this.routeTitle = this.$route.meta.pageTitle;
    }

    get bodyOverlay() {
        return this.$store.state.bodyOverlay;
    }

    get contentAreaClass() {
        if (this.mainLayoutType === 'vertical') {
            if (this.verticalNavMenuWidth == 'default') return 'content-area-reduced';
            else if (this.verticalNavMenuWidth == 'reduced') return 'content-area-lg';
            else return 'content-area-full';
        }
        // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
        else return 'content-area-full';
    }

    get footerClasses() {
        return {
            'footer-hidden': this.footerType == 'hidden',
            'footer-sticky': this.footerType == 'sticky',
            'footer-static': this.footerType == 'static'
        };
    }

    get isAppPage() {
        // @ts-ignore
        return this.$route.meta.no_scroll;
    }

    get isThemeDark() {
        return this.$store.state.theme == 'dark';
    }

    get layoutTypeClass() {
        return `main-${this.mainLayoutType}`;
    }

    get mainLayoutType() {
        return this.$store.state.mainLayoutType;
    }

    get navbarClasses() {
        return {
            'navbar-hidden': this.navbarType == 'hidden',
            'navbar-sticky': this.navbarType == 'sticky',
            'navbar-static': this.navbarType == 'static',
            'navbar-floating': this.navbarType == 'floating'
        };
    }

    get verticalNavMenuWidth() {
        return this.$store.state.verticalNavMenuWidth;
    }

    get windowWidth() {
        return this.$store.state.windowWidth;
    }

    changeRouteTitle(title: string) {
        this.routeTitle = title;
    }

    updateNavbar(val: any) {
        if (val == 'static') this.updateNavbarColor(this.isThemeDark ? '#10163a' : '#fff');
        this.navbarType = val;
    }

    updateNavbarColor(val: any) {
        this.navbarColor = val;
        this.isNavbarDark = val != '#fff';
    }

    updateFooter(val: any) {
        this.footerType = val;
    }

    setNavMenuVisibility(layoutType: string) {
        if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
            this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false);
            this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu');
        } else {
            this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
        }
    }

    toggleHideScrollToTop(val: boolean) {
        this.hideScrollToTop = val;
    }

    updateRouterTransition(val: any) {
        this.routerTransition = val;
    }
}
















































import {Component, Vue} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {NotificationService} from "@/services/NotificationService";
// import AuthService from '@/components/auth/AuthService';

@Component
export default class ProfileDropDown extends Vue {
    // private authService: AuthService;
    @Getter('oidcUser') oidcUser!: any;
    @Action('signOutOidc') signOutOidc!: any;

    constructor() {
        super();
    }

    get activeUserInfo() {
        return this.oidcUser;
    }
}

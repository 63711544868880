































































import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { Component, Vue } from "vue-property-decorator";
import * as signalR from "@microsoft/signalr";
import { Getter } from "vuex-class";
import { NotificationService } from "@/services/NotificationService";
import { SearchResult } from "@/models/SearchResult";
import { NotificationResult, NotificationType } from "@/models/notification/NotificationResult";
import moment from "moment";
import router from '@/router';

@Component({
    components: {
        VuePerfectScrollbar
    }
})
export default class NotificationDropDown extends Vue {
    @Getter("oidcUser") currentUser!: any;
    @Getter("oidcAccessToken") accessToken!: string;
    // unreadNotifications = [
    //     {
    //         index: 0,
    //         title: 'New Message',
    //         msg: 'Are your going to meet me tonight?',
    //         icon: 'MessageSquareIcon',
    //         time: this.randomDate({sec: 10}),
    //         category: 'primary'
    //     },
    //     {
    //         index: 1,
    //         title: 'New Order Recieved',
    //         msg: 'You got new order of goods.',
    //         icon: 'PackageIcon',
    //         time: this.randomDate({sec: 40}),
    //         category: 'success'
    //     },
    //     {
    //         index: 2,
    //         title: 'Server Limit Reached!',
    //         msg: 'Server have 99% CPU usage.',
    //         icon: 'AlertOctagonIcon',
    //         time: this.randomDate({min: 1}),
    //         category: 'danger'
    //     },
    //     {
    //         index: 3,
    //         title: 'New Mail From Peter',
    //         msg: 'Cake sesame snaps cupcake',
    //         icon: 'MailIcon',
    //         time: this.randomDate({min: 6}),
    //         category: 'primary'
    //     },
    //     {
    //         index: 4,
    //         title: 'Bruce\'s Party',
    //         msg: 'Chocolate cake oat cake tiramisu',
    //         icon: 'CalendarIcon',
    //         time: this.randomDate({hr: 2}),
    //         category: 'warning'
    //     },
    // ];
    listNotification: NotificationResult[] = [];
    totalUnViewMessage = 0;
    settings = {
        maxScrollbarLength: 60,
        wheelSpeed: .60,
    };
    connection: any;
    notificationService: NotificationService;
    currentPage = 1;
    totalPage = 0;

    constructor() {
        super();
        this.notificationService = new NotificationService();
    }

    mounted() {
        // Lấy về tổng số thông báo chưa đọc.
        this.notificationService.getNotificationCount()
            .then((count) => {
                this.totalUnViewMessage = count;
            });
        this.search();
        console.log('init notification dropdown');
    }

    created() {
        this.initNotificationConnection();
    }

    elapsedTime(startTime: any) {
        moment(startTime).fromNow();
    }

    // Method for creating dummy notification time
    randomDate({hr, min, sec}: any) {
        let date = new Date()

        if (hr) date.setHours(date.getHours() - hr)
        if (min) date.setMinutes(date.getMinutes() - min)
        if (sec) date.setSeconds(date.getSeconds() - sec)

        return date
    }

    updateIsViewStatus() {
        if (this.totalUnViewMessage > 0) {
            this.notificationService.updateIsView();
        }
    }

    detail(notification: NotificationResult) {
        this.notificationService.updateIsRead(notification.id, true);
        switch (notification.type) {
            // Chi tiết khách hàng.
            case NotificationType.CustomerAddNew:
            case NotificationType.CustomerChangeManager:
            case NotificationType.CustomerSendToManagerApprove:
                this.$router.push({
                    name: 'customerDetail',
                    params: {
                        id: notification.data.id
                    }
                });
                break;
            default:
                break;
        }
    }

    loadMore() {
        this.currentPage++;
        this.search();
    }

    private search() {
        const notificationService = new NotificationService();
        this.showLoading(true);
        notificationService.search(undefined, this.currentPage, 10)
            .then((result: SearchResult<NotificationResult>) => {
                this.showLoading(false);
                this.listNotification = [...this.listNotification, ...result.items];
                this.totalPage = Math.ceil(result.totalRows / 10);
            });
    }

    private initNotificationConnection() {
        const url = `${process.env.VUE_APP_NOTIFICATION_URL}hub`;
        const connection = new signalR.HubConnectionBuilder().withUrl(url, {accessTokenFactory: () => this.accessToken})
            .build();

        connection.start()
            .then(() => {
            })
            .catch((e) => console.log(e));

        connection.on("ReceiveNotification", (payload: NotificationResult) => {
            if (this.$vs) {
                this.$vs.notify({
                    title: payload.title,
                    text: payload.message,
                    color: "primary",
                    click: () => {
                        this.detail(payload);
                    }
                })
            }
            this.totalUnViewMessage = !this.totalUnViewMessage ? 1 : this.totalUnViewMessage + 1;
            this.listNotification = [payload, ...this.listNotification];
        });

        connection.on("NewCustomerCalling", (mobile: string, callId: string) => {
            if(this.$store.state.callId != callId)
            {
                this.$store.state.callId = callId;
                window.open(`/callCenter/new/${mobile}/${callId}`, '_blank');
            }

            console.log(this.$store.state.callId +","+ mobile+ " "+ callId);
            //router.push({ path: `/callCenter/new/${mobile}/${callId}` });
        });

        connection.on("CustomerCalling", (customerId: string, callId: string) => {
            if(this.$store.state.callId != callId)
            {
                this.$store.state.callId = callId;
                window.open(`/callCenter/${customerId}/${callId}/activity`, '_blank');
            }

            console.log(this.$store.state.callId +","+ customerId+ " "+ callId);
            //router.push({ path: `/callCenter/${customerId}/${callId}/activity` });
        });
    }

    private showLoading(isLoading: boolean) {
        setTimeout(() => {
            if (isLoading) {
                this.$vs.loading({
                    color: 'primary',
                    container: '#notification-loading',
                    scale: 0.45
                });
            } else {
                this.$vs.loading.close('#notification-loading > .con-vs-loading')
            }
        }, 100);
    }
}

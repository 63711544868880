import axios from 'axios';
import {SearchResult} from "@/models/SearchResult";
import {NotificationResult} from "@/models/notification/NotificationResult";
import {ActionResult} from "@/models/ActionResult";

export class NotificationService {
    url = `${process.env.VUE_APP_GATEWAY_URL}notification/api/v1/notifications`;

    constructor() {

    }

    search(isRead?: boolean, page = 1, pageSize = 10): Promise<SearchResult<NotificationResult>> {
        return axios.get(this.url, {
            params: {
                isRead: isRead != null && isRead !== undefined ? isRead : '',
                page, pageSize
            }
        })
    }

    updateIsRead(id: string, isRead: boolean) {
        axios.put(`${this.url}/${id}/reads/${isRead}`)
            .then();
    }

    markAllAsRead(): Promise<ActionResult> {
        return axios.post(`${this.url}/mark-all-as-read`);
    }

    updateIsView() {
        axios.put(`${this.url}/update-view-status`)
            .then();
    }

    getNotificationCount(): Promise<number> {
        return axios.get(`${this.url}/count`);
    }
}

export interface NotificationResult<T = any> {
    id: string;
    createTime: string;
    userId: string;
    fullName: string;
    avatar: string;
    image: string;
    isRead: boolean;
    url: string;
    title: string;
    message: string;
    type: NotificationType
    data: T;
}

export enum NotificationType {
    /// <summary>
    /// Tạo mới khách hàng = 0.
    /// </summary>
    CustomerAddNew,
    /// <summary>
    /// Gửi khách hàng chờ duyệt = 1
    /// </summary>
    CustomerSendToManagerApprove,
    /// <summary>
    /// Thay đổi người quản lý
    /// </summary>
    CustomerChangeManager
}

export class GroupSearch {
    name: string;
    groupName: string;
    type: SearchType;
    items: Array<SearchItem> = new Array<SearchItem>();

    constructor(name: string, type: SearchType, items: Array<SearchItem>)
    {
        this.name = name;
        this.type = type;
        this.items = items;
        this.groupName   = SearchType[type];
    }
}

export class SearchItem {
    img: string;
    title: string;
    subTitle: string;
    moreInfo: string;
    id : string;
    type: SearchType;

    constructor (img: string, title: string, subTitle: string, moreInfo: string, id: string, type: SearchType)
    {
        this.img = img;
        this.title = title;
        this.subTitle = subTitle;
        this.moreInfo = moreInfo;
        this.id = id;
        this.type = type;
    }
}

export enum SearchType {
    Contact,
    Order
}
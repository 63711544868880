<template>
  <div class="flex">
    <div class="search-full-container w-full h-full absolute left-0 top-0" :class="{'flex': showFullSearch}" v-show="showFullSearch">
      <vx-auto-suggest
              ref="navbarSearch"
              :autoFocus="showFullSearch"
              search_key="name"
              background-overlay
              class="w-full"
              inputClassses="w-full vs-input-no-border vs-input-no-shdow-focus"
              icon="SearchIcon"
              :placeholder="$t('Tìm khách hàng, đơn hàng...')"
              @input="hnd_search_query_update"
              @selected="selected"
              @closeSearchbar="showFullSearch = false">

        <template v-slot:group="{ group_name }">
          <p class="font-semibold text-primary">{{group_name}}</p>
        </template>

        <!-- Contacts Suggestion -->
        <template v-slot:Contact="{ suggestion }">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <img :src="suggestion.img" class="w-8 h-8 mr-3 rounded-full">
              <div class="leading-none mt-1">
                <p>{{ suggestion.title }}</p>
                <small>{{ suggestion.subTitle }}</small>
              </div>
            </div>
            <small>{{ suggestion.moreInfo }}</small>
          </div>
        </template>

        <template v-slot:Order="{ suggestion }">
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <feather-icon icon="ShoppingCartIcon" class="w-8 h-8 mr-3"></feather-icon>
              <div class="leading-none mt-1">
                <p>{{ suggestion.title }}</p>
                <small>{{ suggestion.subTitle }}</small>
              </div>
            </div>
            <small>{{ suggestion.moreInfo }}</small>
          </div>
        </template>

        <!-- No Items Slot -->
        <template v-slot:noResult="{ group_name }">
          <div class="flex items-center">
            <feather-icon icon="InfoIcon" svgClasses="h-5 w-5" class="mr-4" />
            <span>{{$t('No results found.')}}</span>
          </div>
        </template>

      </vx-auto-suggest>

      <div class="absolute right-0 h-full z-50">
        <feather-icon
                icon="XIcon"
                class="px-4 cursor-pointer h-full close-search-icon"
                @click="showFullSearch = false" />
      </div>
    </div>
    <feather-icon icon="SearchIcon" @click="showFullSearch = true" class="cursor-pointer navbar-fuzzy-search ml-4" />
  </div>
</template>

<script>
  import VxAutoSuggest from '@/components/vx-auto-suggest/VxAutoSuggest.vue';
  import {SearchType} from "@/models/GroupSearch";

  export default {
    components: {
      VxAutoSuggest
    },
    data() {
      return {
        navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
        showFullSearch: false,
      }
    },
    methods: {
      selected(item) {
      //var abc = SearchType[SearchType.Order];

        // var url = item.type == SearchType.Order? `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customers/auto-suggest`:
        //         `${process.env.VUE_APP_ORDER_URL}api/v1/orders/auto-suggest`;

        var url = item.type == SearchType.Order? `/customer/detail/${item.id}/`:
                `/customer/detail/${item.id}/`;
        this.$router.push(url).catch(() => {});

        this.showFullSearch = false;
      },
      hnd_search_query_update(query) {
        // Show overlay if any character is entered
        this.$store.commit('TOGGLE_CONTENT_OVERLAY', query ? true : false);
      }
    }
  }

</script>

<i18n>
  {
  "en":{
  "No results found.": "No results found.",
  "Tìm khách hàng, đơn hàng..." : "Tìm khách hàng, đơn hàng..."
  },
  "vi": {
  "No results found.": "Không tìm thấy kết quả",
  "Tìm khách hàng, đơn hàng..." : "Tìm khách hàng, đơn hàng..."
  }
  }
</i18n>
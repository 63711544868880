import axios from 'axios';
import { SearchResult } from '@/models/SearchResult';
//import { UserSuggestionModel } from '@/components/suggestion/user-suggestion/UserSuggestionModel';
import {SearchItem} from "@/models/GroupSearch";

export interface ShortItem {
    id: string;
    name: string;
}

export class HeaderService {
    url = `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customers`;

    suggestion(searchTerm: string, page = 1, pageSize = 20): Promise<SearchResult<SearchItem>> {
        return axios.get(`${this.url}/auto-suggest`, {
            params: {
                searchTerm, page, pageSize
            }
        })
    }
}
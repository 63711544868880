



































































    import {Component, Vue, Watch, Prop, Ref} from 'vue-property-decorator';
    import {GroupSearch, SearchItem, SearchType} from '@/models/GroupSearch';
    import * as _ from 'lodash';
    import i18n from "@/i18n";
    import { HeaderService } from '@/services/HeaderService';

    @Component({
        components: {

        }
    })

    export default class VxAutoSuggest extends Vue
    {
        @Prop({default: 'Search...'}) placeholder!: string;
        @Prop({default: () => new Object}) initalData: any;
        @Prop() inputClassses: [string, any, any];
        @Prop({default: false}) autoFocus: boolean;
        @Prop({default: false}) showPinned: boolean;
        @Prop({default: 4}) searchLimit: number;
        @Prop({default: false}) hideGroupTitle: boolean;
        //@Ref('input') input!: any

        searchQuery: string = '';
        filteredData: Array<GroupSearch> = [
            new GroupSearch('Khách hàng', SearchType.Contact, []),
            new GroupSearch('Đơn hàng', SearchType.Order, [])
        ]
        debounce: any = null;
        currentSelected: any = "0.0";
        inputFocused: boolean = false;
        insideSuggestions: boolean = false;
        grp_arr: any;
        grp_i: number;
        item_i: number;

        private headerService: HeaderService;

        mounted() {
            if(this.autoFocus) this.focusInput()
        }

        constructor() {
            super();
            this.headerService = new HeaderService();
        }

        beforeMount() {
        }

        @Watch('searchQuery')
        onSearchQueryChange(val: string, oldValue: string) {
            this.$emit('input', val);

            if(val.length == 0)
            {
                return;
            }

            if (this.debounce) {
                this.debounce.cancel();
            }

            this.debounce = _.debounce(() => this.search(val), 500);
            this.debounce();
        }

        @Watch('autoFocus')
        onAutoFocusChange(val: boolean, oldValue: boolean) {
            if(val) this.focusInput();
            else this.searchQuery = '';
        }

        @Watch('filteredData')
        onFilteredDataChange(val: any, oldValue: any) {
        }

        escPressed() {
            this.$emit('closeSearchbar');
            this.searchQuery = '';
        }

        async search(search: string) {
            if(search.length == 0)
            {
                return;
            }

            //Search khách hàng
            const result = await this.headerService.suggestion(search, 1, 100);

            if (result.code > 0) {
                this.filteredData[0].items = result.items;
            } else {
                console.log(result);
            }
        }

        inputInit() {
            // if(Object.entries(this.initalData).length === 0 && this.initalData.constructor === Object) {
            //     this.filteredData = [new GroupSearch('Khách hàng', [new SearchItem('ảnh','Hà', '','', '/config')]), new GroupSearch('Đơn hàng', [new SearchItem('ảnh','Hà', '','', '/route')])];
            // }else {
            //     this.filteredData = this.initalData;
            // }
        }

        updateInputFocus(val = true) {
            if(val) {
                if(this.searchQuery == '') this.inputInit();
                setTimeout(() => {
                    this.inputFocused = true;
                }, 100)
            }
            else {
                if(this.insideSuggestions) return
                setTimeout(() => {
                    this.inputFocused = false;
                }, 100)
                this.escPressed()
            }
        }

        suggestionSelected() {
            if(this.currentSelected > -1) {

                this.grp_i = parseInt(this.currentSelected.split(".")[0]);
                this.item_i = parseInt(this.currentSelected.split(".")[1]);
                this.grp_arr = Object.entries(this.filteredData)
                const groupItems = this.grp_arr[this.grp_i][1].items;

                this.$emit('selected', {id : groupItems[this.item_i].id, type: this.grp_arr[this.grp_i][1].type});

                this.searchQuery = '';
            }
        }

        increaseIndex(val = true) {
            this.grp_i = parseInt(this.currentSelected.split(".")[0]);
            this.item_i = parseInt(this.currentSelected.split(".")[1]);

            this.grp_arr = Object.entries(this.filteredData);
            const active_grp_total_items = this.grp_arr[this.grp_i][1].items.length;
//comment
            if(val) {
                // If active item is not of last item in grp
                if(active_grp_total_items-1 > this.item_i) {
                    this.currentSelected = this.grp_i + "." +  (Number(this.item_i)+1);

                    // If active item grp is not last in grp list
                }else if(this.grp_i < this.grp_arr.length-1) {
                    this.currentSelected = Number(this.grp_i)+1 + ".0";

                    if(this.grp_i + 1 == this.filteredData.length - 1 &&
                        (this.grp_arr[this.grp_i][1].items.length == 0 || this.item_i == (this.grp_arr[this.grp_i][1].items.length - 1))){
                        this.currentSelected = "0.0";
                    }
                } //If lasst item and lasst group then go first item of first group
                else if(this.grp_i == this.filteredData.length - 1 && (active_grp_total_items == 0 || this.item_i == active_grp_total_items - 1)){
                    this.currentSelected = "0.0";
                }
            }else {
                // If active item is not of first item in grp
                if(Number(this.item_i)) {
                    this.currentSelected = this.grp_i + "." +  (Number(this.item_i)-1)

                    // If active item grp  is not first in grp list
                }else if(Number(this.grp_i)) {
                    this.currentSelected = (Number(this.grp_i)-1) + "." + (this.grp_arr[this.grp_i-1][1].items.length-1)
                }//If firt item and first group then go last item of last group
                else if(this.grp_i == 0 && this.item_i == 0){
                    let itemIndex = this.item_i;
                    if(this.grp_arr[this.filteredData.length-1][1].items.length == 0)
                    {
                        itemIndex = this.grp_arr[this.filteredData.length - 2][1].items.length-1

                        this.currentSelected = (this.filteredData.length - 2)+"."+(itemIndex);
                    }
                    else
                    {
                        itemIndex = this.grp_arr[this.filteredData.length - 1][1].items.length - 1;
                        this.currentSelected = (this.filteredData.length - 1)+"."+(itemIndex);
                    }
                }
            }
        }

        focusInput() {
            const ref: any = this.$refs.input;
            ref.$el.querySelector('input').focus()
        }
    }






























import {Component, Prop, Vue} from 'vue-property-decorator';
import Bookmarks from './components/Bookmarks.vue';
import SearchBar from './components/SearchBar.vue';
import NotificationDropDown from './components/NotificationDropDown.vue';
import ProfileDropDown from './components/ProfileDropDown.vue';
import I18n from './components/I18n.vue';

@Component({
    components: {
        Bookmarks,
        SearchBar,
        NotificationDropDown,
        ProfileDropDown,
        I18n
    }
})
export default class TheNavbarVertical extends Vue {
    @Prop({default: '#fff'}) navbarColor!: string;

    get navbarColorLocal() {
        return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor;
    }

    get verticalNavMenuWidth() {
        return this.$store.state.verticalNavMenuWidth;
    }

    get textColor() {
        return {'text-white': (this.navbarColor != '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor != '#fff' && this.$store.state.theme !== 'dark')};
    }

    get windowWidth() {
        return this.$store.state.windowWidth;
    }

    // NAVBAR STYLE
    get classObj() {
        if (this.verticalNavMenuWidth == 'default') return 'navbar-default';
        else if (this.verticalNavMenuWidth == 'reduced') return 'navbar-reduced';
        else if (this.verticalNavMenuWidth) return 'navbar-full';
        else return '';
    }

    showSidebar() {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    }
}

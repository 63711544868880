






















































































































import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import VNavMenuGroup from './VerticalNavMenuGroup.vue';
import VNavMenuItem from './VerticalNavMenuItem.vue';

import Logo from '../Logo.vue';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';

// const VuePerfectScrollbar = require('vue-perfect-scrollbar');

@Component({
    components: {
        VNavMenuGroup,
        VNavMenuItem,
        VuePerfectScrollbar,
        Logo
    }
})
export default class VerticalNavMenu extends Vue {
    @Prop() logo!: string;
    @Prop({default: false}) openGroupHover!: boolean;
    @Prop() parent!: string;
    @Prop({default: true}) reduceNotRebound!: boolean;
    @Prop({default: () => []}) navMenuItems!: any;
    @Prop() title!: string;

    @Ref('verticalNavMenuPs') verticalNavMenuPs!: any;

    clickNotClose = true; // disable close navMenu on outside click
    isMouseEnter = false;
    reduce = false; // determines if navMenu is reduce - component property
    showCloseButton = false; // show close button in smaller devices
    settings = {      // perfectScrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 1,
        swipeEasing: true
    };
    showShadowBottom = false;

    get isGroupActive() {

        return (item: any) => {
            const path = this.$route.fullPath;
            const routeParent = this.$route.meta ? this.$route.meta.parent : undefined;
            let open = false;

            let func = (item: any) => {
                if (item.submenu) {
                    item.submenu.forEach((item: any) => {

                        if (item.url && (path === item.url || routeParent === item.slug)) {
                            open = true;
                        } else if (item.submenu) {
                            func(item);
                        }
                    });
                }
            };
            func(item);
            return open;
        };
    }

    get menuItemsUpdated() {
        let clone = this.navMenuItems.slice();

        let index = 0;
        _.each(this.navMenuItems, function(item){
            if (item.header && item.items.length && (index || 1)) {
                let i = clone.findIndex((ix: any) => ix.header === item.header);

                let subIndex = 0;
                _.each(item.items, function(subItem){
                    clone.splice(i + 1 + subIndex, 0, subItem);

                    subIndex = subIndex + 1;
                })
                // for (let [subIndex, subItem] of item.items.entries()) {
                //
                // }
            }

            index = index + 1;
        });

        // for (let [index, item] of this.navMenuItems.entries()) {
        //     debugger;
        //     if (item.header && item.items.length && (index || 1)) {
        //         let i = clone.findIndex((ix: any) => ix.header === item.header);
        //         for (let [subIndex, subItem] of item.items.entries()) {
        //             clone.splice(i + 1 + subIndex, 0, subItem);
        //         }
        //     }
        // }

        return clone;
    }

    get isVerticalNavMenuActive() {
        return this.$store.state.isVerticalNavMenuActive;
    }

    set isVerticalNavMenuActive(val) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', val);
    }

    get layoutType() {
        return this.$store.state.mainLayoutType;
    }

    get reduceButton() {
        return this.$store.state.reduceButton;
    }

    set reduceButton(val) {
        this.$store.commit('TOGGLE_REDUCE_BUTTON', val);
    }

    get isVerticalNavMenuReduced() {
        return Boolean(this.reduce && this.reduceButton);
    }

    get verticalNavMenuItemsMin() {
        return this.$store.state.verticalNavMenuItemsMin;
    }

    get windowWidth() {
        return this.$store.state.windowWidth;
    }

    @Watch('$route')
    onRoute() {
        if (this.isVerticalNavMenuActive && this.showCloseButton) this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false);
    }

    @Watch('reduce')
    onReduce(val: any) {
        const verticalNavMenuWidth = val ? 'reduced' : this.$store.state.windowWidth < 1200 ? 'no-nav-menu' : 'default';
        this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth);

        setTimeout(function () {
            window.dispatchEvent(new Event('resize'));
        }, 100);
    }

    @Watch('layoutType')
    onChangeLayoutType() {
        this.setVerticalNavMenuWidth();
    }

    @Watch('reduceButton')
    onReduceButton() {
        this.setVerticalNavMenuWidth();
    }

    @Watch('windowWidth')
    onChangeWindowWidth() {
        this.setVerticalNavMenuWidth();
    }

    onSwipeLeft() {
        if (this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = false;
    }

    onSwipeAreaSwipeRight() {
        if (!this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = true;
    }

    psSectionScroll() {
        this.showShadowBottom = this.verticalNavMenuPs.$el.scrollTop > 0 ? true : false;
    }

    mouseEnter() {
        if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false);
        this.isMouseEnter = true;
    }

    mouseLeave() {
        if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', true);
        this.isMouseEnter = false;
    }

    setVerticalNavMenuWidth() {

        if (this.windowWidth > 1200) {
            if (this.layoutType === 'vertical') {

                // Set reduce
                this.reduce = this.reduceButton ? true : false;

                // Open NavMenu
                this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);

                // Set Menu Items Only Icon Mode
                const verticalNavMenuItemsMin = (this.reduceButton && !this.isMouseEnter) ? true : false;
                this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', verticalNavMenuItemsMin);

                // Menu Action buttons
                this.clickNotClose = true;
                this.showCloseButton = false;

                const verticalNavMenuWidth = this.isVerticalNavMenuReduced ? 'reduced' : 'default';
                this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth);

                return;
            }
        }

        // Close NavMenu
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false);

        // Reduce button
        if (this.reduceButton) this.reduce = false;

        // Menu Action buttons
        this.showCloseButton = true;
        this.clickNotClose = false;

        // Update NavMenu Width
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu');

        // Remove Only Icon in Menu
        this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false);


        // if(this.layoutType === 'vertical' || (this.layoutType === 'horizontal' && this.windowWidth < 1200))
        // if (this.windowWidth < 1200) {

        //   // Close NavMenu
        //   this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE' false)

        //   // Reduce button
        //   if (this.reduceButton) this.reduce = false

        //   // Menu Action buttons
        //   this.showCloseButton = true
        //   this.clickNotClose   = false

        //   // Update NavMenu Width
        //   this.$store.dispatch('updateVerticalNavMenuWidth' 'no-nav-menu')

        //   // Remove Only Icon in Menu
        //   this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN' false)

        // } else {

        //   // Set reduce
        //   this.reduce = this.reduceButton ? true : false

        //   // Open NavMenu
        //   this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE' true)

        //   // Set Menu Items Only Icon Mode
        //   const verticalNavMenuItemsMin = (this.reduceButton && !this.isMouseEnter) ? true : false
        //   this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN' verticalNavMenuItemsMin)

        //   // Menu Action buttons
        //   this.clickNotClose   = true
        //   this.showCloseButton = false

        //   const verticalNavMenuWidth   = this.isVerticalNavMenuReduced ? "reduced" : "default"
        //   this.$store.dispatch('updateVerticalNavMenuWidth' verticalNavMenuWidth)
        // }
    }

    toggleReduce(val: any) {
        this.reduceButton = val;
        this.setVerticalNavMenuWidth();
    }
}

